<template>
    <div
        class="pageContainer app-container studentContainer"
        style="position: relative">
        <el-tabs v-model="activeName" type="card" style="" @tab-click="changeTab">
            <el-tab-pane label="" name="td">
        <span slot="label">
          <i>通知公告</i>
        </span>
            </el-tab-pane>
        </el-tabs>
        <el-row style="margin-left: 30px; margin-block: 10px">
            <el-input v-model="formData.news_title" size="small" placeholder="新闻标题"
                      style="max-width: 200px; margin-right: 10px; margin-left: 10px"/>
            <el-date-picker v-model="formData.release_time" type="daterange" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期"
                            style="max-width: 300px; margin-right: 10px; margin-left: 10px"></el-date-picker>

            <el-button size="small" icon="el-icon-search" @click="onSearch" plain type="primary">查询</el-button>
            <el-button size="small" icon="el-icon-refresh" @click="onReset" type="warning">重置</el-button>
            <el-button type="primary" size="small" @click="onAdd">添加</el-button>
        </el-row>
        <el-table stripe :data="dataList" style="width: 100%; margin-bottom: 20px; color: #000" border size="mini">
            <el-table-column prop="news_title" label="新闻标题" min-width="200"></el-table-column>

            <el-table-column prop="release_time" label="发布时间" min-width="120"></el-table-column>
            <el-table-column prop="app_send" label="是否发送通知" width="120">
                <template slot-scope="scope">
                    <div v-if="scope.row.app_send==1">通知</div>
                    <div v-if="scope.row.app_send!=1">不通知</div>
                </template>
            </el-table-column>

            <el-table-column prop="type" label="来源" min-width="120">
                <template slot-scope="scope">
                    <span v-if="scope.row.type == '1'">校内新闻</span>
                    <span v-if="scope.row.type == '2'">后台添加</span>
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="150">
                <template slot-scope="scope">
                    <div class="flexStart">
                        <el-button type="text" size="small" @click="onView(scope.row)" style="margin: 0 !important">详情
                        </el-button>
                        <el-popconfirm title="确定删除吗？" @confirm="delectOne(scope.row)">
                            <el-tag slot="reference" type="danger" size="mini" style="margin: 10px;">删除</el-tag>
                        </el-popconfirm>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog title="新闻详情" :visible.sync="dialogVisible" width="800px">
            <el-form ref="form" :model="ruleForm" label-width="80px">
                <el-form-item label="新闻标题">
                    <el-input :disabled="ruleForm.id?true:false" v-model="ruleForm.news_title" size="small"></el-input>
                </el-form-item>
                <el-form-item label="详情">
                    <UEditor ref="ue" @ready="editorReady" style="width: 100%"></UEditor>
                </el-form-item>
                <el-form-item label="微信通知">
                    <el-radio-group :disabled="ruleForm.id?true:false" v-model="ruleForm.app_send" size="small">
                        <el-radio :label="1">通知</el-radio>
                        <el-radio :label="0">不通知</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div v-if="!ruleForm.id" slot="footer">
                <el-button type="primary" @click="dialogVisible=false" plain size="small">关闭</el-button>
                <el-button type="primary" @click="onSubmit" size="small">提交</el-button>
            </div>
        </el-dialog>
        <div class="block">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="current_page"
                :page-size="per_page"
                layout="total, prev, pager, next"
                :total="count"
            >
            </el-pagination>
        </div>
    </div>
</template>
<script>
import UEditor from "../../../components/ueditor.vue";

console.log(UEditor);
export default {
    components: {
        UEditor,
    },
    data() {
        return {
            editor: null,
            dialogVisible: false,
            count: 0,
            current_page: 1,
            per_page: 20,
            activeName: "td",
            dataList: [],
            ruleForm: {
                detail: null,
                news_title: "",
                sort: 1,
                app_send: 0
            },
            formData: {},
        };
    },
    mounted() {
        console.log(sessionStorage.getItem("auth"));
        this.getlist();
    },
    methods: {
        getlist() {
            this.$http
                .post("api/sys_news_list", {
                    page: this.current_page,
                    pagesize: this.per_page,
                    type: 2,
                    news_title: this.formData.news_title,
                    release_time: this.formData.release_time,
                })
                .then((res) => {
                    this.dataList = res.data.data;
                    this.count = res.data.page.count;
                    this.current_page = res.data.page.current_page;
                    this.per_page = res.data.page.per_page;
                });
        },
        changeTab() {
            this.getlist();
        },
        onSearch() {
            this.getlist();
        },
        onReset() {
            this.formData = {};
            this.getlist();
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            this.current_page = val;
            this.getlist();
        },
        onAdd() {
            let _this = this
            this.ruleForm = {
                detail: "",
                news_title: "",
                sort: 1,
                app_send: 0
            };
            this.dialogVisible = true;
            setTimeout(() => {
                _this.editor.setContent('');
            }, 300);
        },
        onView(e) {
            let _this = this
            this.ruleForm = e;
            this.dialogVisible = true;
            setTimeout(() => {
                _this.editor.setContent(e.detail);
            }, 300);
        },
        editorReady(editorInstance) {
            editorInstance.setContent(this.ruleForm.detail);
            editorInstance.addListener("contentChange", () => {
                this.ruleForm.detail = editorInstance.getContent();
            });
            this.editor = editorInstance;
        },
        onSubmit() {
            this.$http
                .post("api/sys_news_save", {
                    id: this.ruleForm.id,
                    detail: this.ruleForm.detail,
                    news_title: this.ruleForm.news_title,
                    release_time: new Date(),
                    type: 2,
                    app_send: this.ruleForm.app_send,
                })
                .then((res) => {
                    this.dialogVisible = false;
                    setTimeout(() => {
                        this.getlist();
                    }, 300);
                });
        },
        delectOne(e) {
            this.$http
                .post("api/sys_news_delete", {
                    id: e.id,
                })
                .then((res) => {
                    this.getlist();
                });
        },
    },
};
</script>
<style>
.newsDetail img {
    width: 100% !important;
    height: auto !important;
}
</style>


